import { useRouter  } from "next/router";
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import "bootstrap/scss/bootstrap.scss";
//is user enter
import useList from "../components/isAuth/isAuth"
import AuthPage from "./AuthPage/index.js"
// ========= Plugins CSS START =========
import "../public/css/plugins/feature.css";
import "../public/css/plugins/animation.css";
import "../node_modules/sal.js/dist/sal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tooltip/dist/react-tooltip.css";
// ========= Plugins CSS END =========

import "../public/scss/style.scss";
import Loading from "@/components/Loading/Loading";
import { configureStore } from "@reduxjs/toolkit";
import feedbackReducer from "@/slices/feedbackSlice";
import {messagesReducer} from "@/slices/messagesReducer";
import { Provider } from "react-redux";
import Home from "./Home/index.js"


//multilangues
import enMessages from "../locales/en.json";
import trMessages from "../locales/tr.json";
import deMessages from "../locales/de.json";
import frMessages from "../locales/fr.json";
const messages = {
  en: enMessages,
  tr: trMessages,
  de: deMessages,
  fr: frMessages,
};


export default function App({ Component, pageProps }) {

  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState("tr"); // Varsayılan dil
  
  const [authenticated, setAuthenticated] = useState(false);
  const [login, setLogin] = useState(false);
  const router = useRouter();
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");

    const handleStart = (url) => url !== router.asPath && setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);
  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };
  const list = useList()
 
  const store = configureStore({
    reducer: {
      feedback: feedbackReducer,
      messages:messagesReducer,
    },
  });

 
  if (!list[1] && !(router.pathname === "/" || router.pathname === "/AuthPage" || router.pathname.startsWith("/confirm-password"))) {
    return (
      <Provider store={store}>
      <IntlProvider locale={language} messages={messages[language]}>
        {loading ? <Loading /> : <Home />}
      </IntlProvider>
          </Provider>
  
    );
  }
  return (
    <Provider store={store}>
    <IntlProvider locale={language} messages={messages[language]}>
      {loading ? <Loading /> : <Component {...pageProps} handleLanguageChange={handleLanguageChange} />}
    </IntlProvider>
        </Provider>

  );
}
